"use strict";

/**
* Checks if the device is a touch screen
* @returns {boolean} - true if the device is a touch screen, false otherwise
*/
function isTouchScreen() {
    return (("ontouchstart" in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

/**
* Generates a template for the popover
* @returns {string} - The template for the popover
*/
function popoverTemplate() {
    return `<div class="popover" role="tooltip">
                <div class="arrow"></div>
                <h3 class="popover-header"></h3>
                <div class="popover-body"></div>
            </div>`;
}

/**
* Closes the popover when clicking outside of it
*/
function closePopoverOnClickOutside() {
    $(document).on("click", function (e) {
        $("[data-toggle='popover'],[data-original-title]").each(function () {
            var $this = $(this);
            // the "is" for buttons that trigger popups
            // the "has" for icons within a button that triggers a popup
            if (!$this.is(e.target) && $this.has(e.target).length === 0 && $(".popover").has(e.target).length === 0) {
                (($this.popover("hide").data("bs.popover") || {}).inState || {}).click = false; // fix for BS 3.3.6
            }
        });
    });
}

/**
* Initializes the badges popover
* @param {Object} popoverTrigger - the trigger element for the popover
* @param {Object} popoverTemplateContent - the template content for the popover
* @param {Object} commonConfig - the common configuration for the popover
*/
function initPopover() {
    const commonConfig = {
        template: popoverTemplate(),
        container: "body",
        html: true
    };

    $(".js-popover").each(function () {
        const $currentPopover = $(this);
        const customBoundarySelector = $($currentPopover.data("custom-boundary"))[0] || "viewport";
        const container = $currentPopover.data("container") || "body";
        const placement = $currentPopover.data("placement") || "top";
        const hasImage = /<img/.test($currentPopover.data("content"));
        const offset = $currentPopover.data("popover-offset") || 0;

        $currentPopover.popover({
            ...commonConfig,
            trigger: isTouchScreen() ? "click" : "hover",
            boundary: customBoundarySelector,
            container: container,
            placement: placement,
            offset: offset
        });

        // Prevents image flickering on popover
        if (hasImage) {
            $currentPopover.popover("show").popover("hide");
        }

        $currentPopover.on("shown.bs.popover", ({ target }) => {
            // This piece of code gets the connected popover (the "tip" is the popover element)
            const openedPopover = $(target).data("bs.popover").tip;
            $(openedPopover).popover("update");
        });
    });

    // If it"s a touch screen, close the popover when clicking outside of it
    if (isTouchScreen()) {
        closePopoverOnClickOutside();
    }
}

module.exports = {
    initPopover
};
