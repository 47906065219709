"use strict";

const Swiper = require("swiper").default;
const { Navigation, Pagination } = require("swiper");
const gtm = require("../../gtm");
const { initPopover } = require("../../utils/popoverUtils");

/**
* Returns the default configuration for the carousel
* @param {string} carouselWrapperClass - the class of the carousel wrapper
* @returns {Object} - the default configuration for the carousel
*/
const getDefaultConfig = (carouselWrapperClass) => {
    const wrapperClass = carouselWrapperClass || ".js-ahumada-carousel-wrapper";

    return {
        loop: false,
        observer: true,
        modules: [Pagination, Navigation],
        pagination: {
            el: `${wrapperClass} .swiper-pagination`,
            type: "bullets",
            clickable: true
        },
        navigation: {
            nextEl: `${wrapperClass} .swiper-button-next`,
            prevEl: `${wrapperClass} .swiper-button-prev`,
        },
        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 20,
            },
        },
        on: {
            init() {
                initPopover();
            }
        }
    };
};

/**
* Initializes the carousel
* @param {string} [element="js-ahumada-carousel"] - The carousel element selector
* @param {Object} [configs={}] - The configuration of the carousel
* @param {string} [carouselWrapperClass=''] - The wrapper class of the carousel
*/
function initCarousel(element = "js-ahumada-carousel", configs = {}, carouselWrapperClass = "") {
    const selector = element;
    const defaultConfig = getDefaultConfig(carouselWrapperClass);
    const settings = { ...defaultConfig, ...configs };
    var eventWasTriggered = false;

    // eslint-disable-next-line no-unused-vars
    const carousel = new Swiper(selector, settings);

    $(window).on("scroll", function () {
        if (!eventWasTriggered && $(selector).is(":visible") && $(selector).find(".product-tile").length) {
            var windowHeight = $(window).height();
            var scrollTop = $(window).scrollTop();
            var elementOffset = $(selector).offset().top;

            if (scrollTop + windowHeight > elementOffset) {
                // Run tracking function when the element is on the screen
                gtm.viewItemList($(selector));
                eventWasTriggered = true;
            }
        }
    });
}

function getEinsteinCarouselConfigs(element) {
    const $element = $(element);
    const { mobileSlidesToDisplay, tabletSlidesToDisplay, desktopSlidesToDisplay } = $element.data();
    return {
        breakpoints: {
            0: {
                slidesPerView: mobileSlidesToDisplay,
                spaceBetween: 16
            },
            544: {
                slidesPerView: tabletSlidesToDisplay,
                spaceBetween: 16
            },
            1024: {
                slidesPerView: desktopSlidesToDisplay,
                spaceBetween: 16
            }
        }
    };
}

function startEinsteinCarousel() {
    $("body").on("carousel:setup", function () {
        initCarousel(".einstein-swiper-init", getEinsteinCarouselConfigs(".js-einstein-carousel"), ".js-einstein-carousel");
    });
}

$(document).ready(function () {
    startEinsteinCarousel();
});
